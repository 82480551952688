$font-dir: '/fonts/';

@mixin fontface($name, $font-files, $weight: false, $style: false){
	@font-face {
		font-family: quote($name);
		src: url("#{$font-dir}#{$font-files}.woff2") format("woff2"),
		     url("#{$font-dir}#{$font-files}.woff") format("woff");
		@if $weight { font-weight: $weight; }
		@if $style { font-style: $style; }
	}
}