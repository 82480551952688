.gallery {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1em;
    list-style: none;
    padding: 0;
}

.gallery__item {
    transform: scale(.90);
    transition: .25s ease all;

    &:hover {
        transform: scale(1);
        box-shadow: 0 15px 10px -10px transparentize($color: #333333, $amount: 0.5);
    }
}

.gallery__thumb {
    display: block;
}