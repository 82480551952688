.home {
    display: flex;
    align-items: flex-start;

    &__headshot { flex: 2; }
    &__news { flex: 1; }
}

.home__news {
    margin-left: 1em;
}