.quotes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.quote {
    flex: 1 1 30%;
    margin: 0 2em 2em 0;
    min-width: 200px;
    max-width: 30%;

    @media screen and (max-width: 479px) {
        max-width: 100%;
    }
}