.nav {
    position: fixed;
    left: 0;
    bottom: 2.5em;
    width: 100%;
    background: #325596;
    padding: 0;
    z-index: 2;
    font-family: 'Colaborate', sans-serif;
    font-size: 1.5em;

    @media screen and (max-width: 767px) {
        position: static;
        font-size: 1em;
    }
}

.nav__list {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.nav__item {
    margin: 0.5em;
}

.nav__link {
    color: #ccc;
    padding: 1em;
    text-decoration: none;
    border-radius: 10px;

    &:hover {
        background: #a67e34;
        color: #2a2a2a;
    }

    &.active {
        color: #f2f2f2;
    }
}