.banner {
    text-align: center;
    border-bottom: 1px solid #000;
    font-family: "Candela", sans-serif;
}

.banner__tagline {
    font-size: 1.25em;

    h2,
    h3 {
        border: 0;
        margin: 0 0 0.25em;
    }
}