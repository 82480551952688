.resume {
    width: 100%;
    border-collapse: collapse;

    tr:hover { background: transparentize($color: #fff, $amount: 0.75); }

    td {
        padding: 4px;
        vertical-align: top;
    }

    td:nth-child(1) { width: 40%; }
    td:nth-child(2) { width: 25%; }
    td:nth-child(3) { width: 35%; }
}

.resume__theatre td {
    padding-top: 1em;
    font-weight: bold;
}

.resume__show {
    font-style: italic;
}

.resume__link {
    text-decoration: none;
    color: #2a2a2a;

    &:hover { color: blue; }
}

@media screen and (max-width: 767px) {
    .resume {
        tr {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 6px;
            
            td {
                &:nth-child(1) { flex: 100%; }
                &:nth-child(2) { flex: 50%; }
                &:nth-child(3) { flex: 50%; }
            }
        }
    }
}