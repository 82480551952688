.topper {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-start;
}

.topper__text {
    margin: 0 1em;
}

.topper__pdf {
    display: flex;
    align-items: center;
    padding: 2em 1.5em;
}